'use client';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useEffect } from 'react';

// export let reactPlugin: ReactPlugin;
export let applicationInsights: ApplicationInsights;

export const AzureApplicationInsights = () => {
  useEffect(() => {
    // reactPlugin = new ReactPlugin();
    applicationInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.NEXT_PUBLIC_APP_INSIGHTS_CONNECTION_STRING,
        instrumentationKey: process.env.NEXT_PUBLIC_APP_INSIGHTS_INSTRUMENTATION_KEY,
        // extensions: [reactPlugin as any],
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        samplingPercentage: 20,
      },
    });
    applicationInsights.loadAppInsights();
    applicationInsights.trackPageView();

    // if (user) {
    //   applicationInsights.context.user.id = String(user.userName);
    //   applicationInsights.context.user.authenticatedId = String(user.userId);
    //   applicationInsights.context.user.setAuthenticatedUserContext(String(user.userId), String(user.userName));
    // }

    // applicationInsights.addTelemetryInitializer(env => {
    //   env.tags = env.tags || [];
    //   env.tags['ai.cloud.role'] = 'testTag';
    // });
  }, []);

  return null;
};

export const enum TRACK_EVENTS {
  CreateOrUpdateSpotTrack = 'CreateOrUpdateSpotTrack',
  ApprovalStatusChange = 'ApprovalStatusChange',
  CreateCampaign = 'CreateCampaign',
  ScriptUpload = 'ScriptUpload',
  SSO = 'SSO',
  FileUpload = 'FileUpload',
  SpotMix = 'SpotMix',
  Login = 'Login',
}
